
import * as _ from 'lodash'

import CustomActivatorSelect from '@/components/inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import { defineComponent, ref, computed, onMounted, getCurrentInstance } from 'vue'
import SettingsForm from './SettingsForm.vue'
import { getDefaultWhatsappAppSettings, mapWhatsappAppSettings } from '../../../helpers/whatsapp'
import { CONNECTOR_TYPES } from '../../../vars/general'
import { Connector } from '@t/main'
import api from '../../../api/api'
import SpinnerLoader from '../../SpinnerLoader.vue'
import { fetchConnector } from '../../../api/projects'
import useRouter from '../../../hooks/useRouter'
import { yClientsService } from '../../../services/yClients'
import { useProject } from '@root/src/hooks/useProject'
export default defineComponent({
    components: { BaseInputOutlined, CustomActivatorSelect, SettingsForm, SpinnerLoader },
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },
        connector: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { router } = useRouter(root)
        const { project } = useProject()
        const ycMpConnectionStatusText = ref<string>()
        const currentConnector = computed<Connector>(() => {
            const connector =
                createdConnector.value ??
                props.connector ??
                root.$store.state.project.loggedAddingConnector ??
                root.$attrs.createdConnector
            return connector as Connector
        })
        const yClientsConnector = computed<Connector>(() =>
            root.$store.getters
                .projectById(root.$router.currentRoute.params.id)
                .connectors.find((el: Connector) => el.connectorType === CONNECTOR_TYPES.yClients)
        )
        const dikidiConnector = computed<Connector>(() =>
            root.$store.getters
                .projectById(root.$router.currentRoute.params.id)
                .connectors.find((el: Connector) => el.connectorType === CONNECTOR_TYPES.dikidi)
        )
        const appCreated = computed<boolean>(() => Boolean(currentConnector.value.appCodeImmutable))
        const saveButtonText = computed(() =>
            !props.isChange && appCreated.value ? 'Завершить' : 'Сохранить'
        )
        const createdConnector = ref(null)
        const settings = ref<any>({})
        const loading = ref<boolean>(true)
        const createApp = async () => {
            console.log('create')
            loading.value = true
            const props = mapWhatsappAppSettings(settings.value, 'create')
            if (settings.value.onlyForMailing) {
                props.salonId = 0
            }
            const { data, error } = await api.networkFixer.createApp({
                crmConnectorId: dikidiConnector.value?.connectorId ?? yClientsConnector.value?.connectorId,
                connectorId: currentConnector.value.connectorId,
                data: props,
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка')
                return
            }
            emit('createConnector', { useRedirect: false })
            createdConnector.value = (await fetchConnector(currentConnector.value.connectorId)).data
            loading.value = false
        }
        const save = async () => {
            if (appCreated.value) {
                await updateApp()
                await root.$store.dispatch('project/fetchProject', currentConnector.value.projectId)
                goToProject({ isNeedUseRouter: false })
            } else {
                await createApp()
                await root.$store.dispatch('project/fetchProject', currentConnector.value.projectId)
            }
        }
        const cancel = () => {
            emit('cancel')
        }
        const goToProject = (opts: { isNeedUseRouter: boolean } = { isNeedUseRouter: true }) => {
            // const path = `/project/${root.$router.currentRoute.params.id}`
            // if (opts.isNeedUseRouter) router.push(path)
            // if (!opts.isNeedUseRouter) window.location.replace(path)
            emit('endSettings')
        }
        const ycSalonId = computed(() => _.first(yClientsConnector.value?.companyIds))

        const updateApp = async () => {
            console.log('update')
            loading.value = true
            const { data, error } = await api.networkFixer.updateApp({
                connectorId: currentConnector.value.connectorId,
                data: _.omitBy(mapWhatsappAppSettings(settings.value, 'edit'), 'triggers'),
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка')
                return
            }
            emit('changeSettings', {
                settings: { phone: settings.value.phone },
            })

            loading.value = false
        }
        const fetchApp = async () => {
            loading.value = true
            settings.value = props.isChange
                ? await api.networkFixer
                      .getApp({ connectorId: currentConnector.value.connectorId })
                      .then(res => res.data!.data)
                : await getDefaultWhatsappAppSettings({
                      channel: { 'basis-api': {} },
                      connectorId: currentConnector.value.connectorId,
                      salonId: _.first(yClientsConnector.value?.companyIds) as number,
                      yClientsConnectorId: yClientsConnector.value?.connectorId,
                  })
            loading.value = false
        }
        onMounted(async () => {
            console.log(root, 'timezoneTest')
            await fetchApp()
            if (props.isChange) {
                if (!settings.value.disabled) {
                    const instanceAlive = await api.networkFixer
                        .checkWaInstanceAlive({
                            connectorId: currentConnector.value.connectorId,
                        })
                        .then(res => res.data?.alive)

                    if (!instanceAlive) {
                        loading.value = true
                        await api.networkFixer.connectWaInstance({
                            connectorId: currentConnector.value.connectorId,
                        })
                        await fetchApp()
                        loading.value = false
                    }
                }
                if (yClientsConnector.value) {
                    const ycConnectionStatus = await api.connectors.yClients
                        .getConnectionStatus(yClientsConnector.value.connectorId)
                        .then(res => res.data)
                    ycMpConnectionStatusText.value = yClientsService.getConnectionStatusText(
                        ycConnectionStatus!.status
                    )
                }
            }
        })
        return {
            currentConnector,
            settings,
            loading,
            saveButtonText,
            appCreated,
            createApp,
            updateApp,
            save,
            mapWhatsappAppSettings,
            cancel,
            ycMpConnectionStatusText,
            yClientsConnector,
            ycSalonId,
        }
    },
})
